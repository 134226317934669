import { get as _get, merge as _merge } from 'lodash-es';
import { color, _selector } from '../helpers';
import { setSwiperCss } from '../helper/swiper';

import General from './General';

export default (subElement, subElementType, element, template) => {
  const { desktop, mobile } = General(subElement, subElementType, element, template);

  const { uid, type } = element;
  const baseSelector = _selector(uid, type);

  setSwiperCss(template, desktop, element);

  if (subElementType === 'button') {
    const hover = _get(subElement, 'desktop.hover');
    const buttonTextSelector = _get(element, 'subElements.buttonText.selector');
    if (hover && buttonTextSelector) {
      _merge(
        desktop,
        color(
          null,
          null,
          hover.fontColor,
          false,
          template,
          `${baseSelector} ${subElement.selector}:hover ${buttonTextSelector}`,
        ),
      );
    }
  }

  return {
    desktop,
    mobile,
  };
};
